(function ($) {

	var initFormEdit = function() {
		$('form.edit-status').submit(function(e) {
			e.preventDefault();
			var formData = new FormData($(this)[0]);
			var form = $(this);
				$.ajax({
					url: $(this).attr('action'),
					data: formData,
					processData: false,
					contentType: false,
					type: 'post',
					dataType: 'json',
				}).done(function(data) {
					form.find('.help-block.with-errors').remove();
					form.find('.has-error').removeClass('has-error');
					if (data.status == 'bad') {
						for (var index in data.errors) {
							form
								.find('.file-input-name')
								.after(
									'<div class="help-block with-errors">' +
									'<ul class="list-unstyled">' +

									(function(errors) {
										result = '';
										for (var k in errors) {
											result += '<li>' + errors[k] + '</li>'
										}
										return result;
									}) (data.errors[index]) +

									'</ul>' +
									'</div>'
								)
								.parent()
								.addClass('has-error');
						}
						bootbox.alert(
							'Validation errors, please fix.'
						);
					} else {
						form.find('.status-current-icon img').attr('src',data.newPhotoName);
						form.find('.status-preview-img').removeAttr('src');
						form.find('.file-input-name').text('');
						bootbox.alert(
							'Status is successfully updated'
						);
						$('.modal.fade.in').modal('hide');
						$.get($('.statuses-list').attr('update-href'), {}, function(data) {
							$('.statuses-list').html(data);
						});
					}
				});
		});
	};

	var initDeleteStatus = function() {
		$(document).on('click', '.deleteStatus', function(e) {
			e.preventDefault();
			var deleteUrl = $(this).attr('delete-href');
			var statusName = $(this).attr('status-name');
			bootbox.dialog({
				message:"Click Delete to permanently remove "+statusName+" from the Carrier Download List "
						+ "This will be deleted from all existing carrier profiles",
				closeButton:true,
				animate:true,
				buttons:{
					"Cancel":{
						className:"btn btn-default",
						callback:function(result){
							this.modal('hide');
						}
					},
					"Delete":{
						className:"btn btn-danger",
						callback:function(result) {
							if (result) {
								window.location = deleteUrl;
							}
						}
					},
				}
			});
		});
	};

	var initPhotoPreview = function() {
		$('.addForm input[name=image]').change(function(event) {
			var tmpPath = URL.createObjectURL(event.target.files[0]);
			$(this).parents('.status-image').next().find('img').attr('src', tmpPath);
		});
		$('.edit-status input[name=image]').change(function(event) {
			var tmpPath = URL.createObjectURL(event.target.files[0]);
			$(this).parents('.status-new-icon').next().find('img').attr('src', tmpPath);
			$(this).parents('.status-new-icon').next().find('.status-preview-preset').removeClass('status-preview-preset-empty');
		});
		$('form.edit-status .modal-footer button[type="button"], .modal .close').on('click', function () {
			$('.modal').find('input[type="file"]').val('');
			$('.modal').find('.file-input-name').empty();
			$('.modal').find('.status-preview img').removeAttr('src');
			$('.modal').find('.status-preview-preset').addClass('status-preview-preset-empty');
			$('.modal').find('.help-block.with-errors').remove();
			$('.modal').find('.has-error').removeClass('has-error');
		});
	};

	initPhotoPreview();
	initFormEdit();
	initDeleteStatus();

}) (jQuery);