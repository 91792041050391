(function ($) {
    var syncEmailFields = function () {
      $(document).on('click','.show-reset-psw-form',function(){
          $('.reset-psw-form-email').val($('.email').val());
      });
    };
    var initPasswordChange = function () {
        $(document).on('click','.change-password',function () {
            var form = $('#edit_password').serialize();
            $.post('/admin/users/reset-password',form,function (response) {
                $('.flash-ajax').empty();
                if(response.status == 'error'){
                    $('.flash-ajax').append('<div class="col-xs-12 col-md-12 alert alert-danger"></div>');
                    for(var i = 0; i < response.message.length; i++){
                        $('.flash-ajax .alert').append('<p class="text-danger">'+response.message[i]+'</p>');
                    }
                }
                if(response.status == 'success'){
                    $('.flash-ajax').append('<div class="col-xs-12 col-md-12 alert alert-success"></div>');
                    for(var i = 0; i < response.message.length; i++){
                        $('.flash-ajax .alert').append('<p class="text-success">'+response.message[i]+'</p>');
                    }
                }
            },'json');
        });
    };
    var initDeleteUserStatus = function() {
        $(document).on('click', '.deleteUser', function(e) {
            e.preventDefault();
            var deleteUrl = $(this).attr('delete-href');
            var statusName = $(this).attr('status-name');
            bootbox.dialog({
                message:"Click Delete to permanently remove "+statusName+" from the Carrier user List ",
                closeButton:true,
                animate:true,
                buttons:{
                    "Cancel":{
                        className:"btn btn-default",
                        callback:function(result){
                            this.modal('hide');
                        }
                    },
                    "Delete":{
                        className:"btn btn-danger",
                        callback:function(result) {
                            if (result) {
                                window.location = deleteUrl;
                            }
                        }
                    },
                }
            });
        });
    };
    var datatableUserManagement = function () {
        /* Create an array with the values of all the input boxes in a column */
        $.fn.dataTable.ext.order['dom-text'] = function  ( settings, col )
        {
            return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
                return $('input', td).val();
            } );
        };

        /* Create an array with the values of all the input boxes in a column, parsed as numbers */
        $.fn.dataTable.ext.order['dom-text-numeric'] = function  ( settings, col )
        {
            return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
                return $('input', td).val() * 1;
            } );
        };

        /* Create an array with the values of all the select options in a column */
        $.fn.dataTable.ext.order['dom-select'] = function  ( settings, col )
        {
            return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
                return $('select', td).val();
            } );
        };

        /* Create an array with the values of all the checkboxes in a column */
        $.fn.dataTable.ext.order['dom-checkbox'] = function  ( settings, col )
        {
            return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
                return $('input', td).prop('checked') ? '1' : '0';
            } );
        };
        $('.user-management').DataTable({
            "columns": [
                null,
                null,
                { "orderDataType": "dom-checkbox" }
            ],
            "columnDefs": [
              { "targets": [3], "orderable": false }
            ],
            "order": [[ 0, "asc" ]]
        });
    };

    syncEmailFields();
    initPasswordChange();
    initDeleteUserStatus();
    datatableUserManagement();
}) (jQuery);