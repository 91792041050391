(function($) {

	var form = $('.carrier-form');
	var initLog = function() {
    	form.find('input[type=submit]').click(function() {
            form.submit();
    	})
    };

	var initModalSave = function() {

		$('.modalAddLine .save').click(function() {
			var lineId = $(this).attr('line-id');
			var selector = '.lines-list[line-id=' + lineId + ']';
			var inputSelector = '.lines-input[line-id=' + lineId + ']';
			var lineItems = $('#modalAddLine_' + lineId + ' [name="line_items[]"]:checked');
			$(selector).html('');
			$(inputSelector).html('');
			lineItems.each(function() {
				var lineItemStatus = $('#modalAddLine_' + lineId + ' [name=line_item_status_id][line-item=' + $(this).val() + ']');
				$(selector).append(
					'<tr>' +
						'<td>' + $(this).parent().next().text() + '</td>' +
						'<td><img src="' + lineItemStatus.find('option:selected').first().data('image') + '"></td>' +
					'</tr>'
				);
				$(inputSelector).append(
					'<input type="hidden" name="lines[' + lineId + '][]" value="' + $(this).val() + '">' +
					'<input type="hidden" name="lines_statuses[' + lineId + '][' + $(this).val() + ']" value="' + lineItemStatus.val() + '">'
				);
			});
			$('#modalAddLine_' + lineId).modal('hide');
		});
	};

	var initLineControl = function() {

		$(document).on('click', '.carrier-lines .edit-item', function() {
			$('#modalEditLine').modal('show');
		});

		$(document).on('click', '.carrier-lines .remove-item', function() {
			if (!confirm('Delete this item?')) {
				return;
			}
			$(this).parent().parent().remove();
		});
	};

	var initToggle = function() {

		$('.info-toggle').click(function() {
			$(this).parent().next().slideToggle();
			$(this).find('.fa').toggleClass('fa-angle-right').toggleClass('fa-angle-down');
		})
	};

	var initItemsToggle = function() {
		$('.selectAllLines').change(function() {
			var isChecked = $(this).prop('checked');
			$(this).parent().parent().parent().next().find('input[type=checkbox]').each(function() {
				$(this).prop('checked', isChecked);
			});
		})
	};

    var initDownloadForms = function() {

        var updateFormsList = function() {
            $.get($('.download-forms-list').attr('update-href'), {}, function(data) {
                $('.download-forms-list').html(data);
            })
        };

        $(document).on('click','a[data-target="#modalDownloadForms"]',function () {
            $('body').removeAttr('style');
        });

        // Add new form
        $(document).on('submit', '#addDownloadForm', function(e) {
            e.preventDefault();
            var formData = new FormData($(this)[0]);
            var form = $(this);
            var title = $(this).find('input[name="title"]').val();
            $.ajax({
                url: $(this).attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                type: 'post',
                dataType: 'json',
            }).done(function(data) {
                form.find('.help-block.with-errors').remove();
                form.find('.has-error').removeClass('has-error');
                if (data.status != 'ok') {
                    for (var index in data.errors) {
                        form
                            .find('.file-input-name')
                            .after(
                                '<div class="help-block with-errors">' +
                                    '<ul class="list-unstyled">' +

                                        (function(errors) {
                                            result = '';
                                            for (var k in errors) {
                                                result += '<li>' + errors[k] + '</li>'
                                            }
                                            return result;
                                        }) (data.errors[index]) +

                                    '</ul>' +
                                '</div>'
                            )
                            .parent()
                            .addClass('has-error');
                    }
                    $('#modalDownloadForms .modal-header .close').trigger("click");
                    $('body').removeAttr('style');
                    bootbox.alert(
                        'Please choose file and add label.',function () {
                            setTimeout(function () {

                                $('a[data-target="#modalDownloadForms"]').trigger('click');
                            },400);

                        }
                    );
                } else {
                    bootbox.alert(
                        'Download form is successfully created'
                    );
                    updateFormsList();
                    var txt = $(tinymce.get('download_info').getBody()).html()+'<p class="form-link" data-path="'+data.file_path+'"><span style="background-color: #ffe52c;"><a href="/download-forms/download/'+data.file_path+'">Form: '+title+'</a></span></p>';
                    $(tinymce.get('download_info').getBody()).html(txt);
                }
            });
        });

        // Edit form text
        $(document).on('click', '.editFormText', function(e) {
            e.preventDefault();
            updateUrl = $(this).attr('update-href');
            var oldText = $(this).closest('tr').find('td:first').text();
            bootbox.prompt(
                {
                    title:"Text to Display",
                    value:oldText,
                    callback:function(result) {
                        if (result !== null) {
                            $.ajax({
                                url: updateUrl,
                                data: {
                                    title: result,
                                    _token: $('meta[name="csrf-token"]').attr('content'),
                                },
                                type: 'post',
                                dataType: 'json',
                            }).done(function(data) {
                                bootbox.alert(
                                    'Download form is successfully updated'
                                );
                                updateFormsList();
                                var txt = $(tinymce.get('download_info').getBody()).html();
                                var regex = new RegExp('<p class="form-link" data-path="'+data.file_path+'".*>(<span.*>)?<a.*>.*'+oldText+'<\/a>(<\/span>)?<\/p>','img');
                                
                                txt = txt.replace(regex,'<p class="form-link" data-path="'+data.file_path+'"><span style="background-color: #ffe52c;"><a href="/download-forms/download/'+data.file_path+'">Form: '+result+'</a></span></p>');
                                $(tinymce.get('download_info').getBody()).html(txt);
                                $.post('/update_download_info',
                                    {
                                        carrierId:$('.carrier-form').data('carrier-id'),
                                        downloadInfo:txt,
                                        _token: $('meta[name="csrf-token"]').attr('content'),
                                    },
                                function (response) {});
                            });
                        }
                    }
                }
            );
        });

        $(document).on('click', '.removeForm', function(e) {
            e.preventDefault();
            deleteUrl = $(this).attr('delete-href');
            var title = $(this).closest('tr').find('td:first').text();
            bootbox.confirm(
                'Click OK to permanently remove this form',
                function(result) {
                    if (result) {
                         if (result !== null) {
                            $.ajax({
                                url: deleteUrl,
                                data: {
                                    title: result,
                                    _token: $('meta[name="csrf-token"]').attr('content'),
                                },
                                type: 'post',
                                dataType: 'json',
                            }).done(function(data) {
                                bootbox.alert(
                                    'Download form is successfully deleted'
                                );
                                updateFormsList();
                                var txt = $(tinymce.get('download_info').getBody()).html();
                                var regex = new RegExp('<p class="form-link" data-path="'+data.file_path+'".*><span.*><a.*>.*'+title+'<\/a><\/span><\/p>','img');
                                txt = txt.replace(regex,'');
                                $(tinymce.get('download_info').getBody()).html(txt);
                            });
                        }
                    }
                }
            );
        });
        $(document).on('click', '.carrier_download .send-request', function(){
            $('.carrier_download').submit();
            $('#modalDownloadInstructions .modal-header .close').trigger("click");
            $(document).find('.modal-backdrop.fade.in').remove();
            $('body').removeAttr('style').removeClass('modal-open');
            $.post('/removeItems',
                {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    carrierIds: []
                },
                function (response) {
                    if(response.status == 'ok'){
                        $.get('/basket', {}, function(data) {
                            $('.basket-row').html(data);
                            $(document).find('table .full-info').each(function () {
                                $(this).find('a.addToBasket span').text('Add to My Download Setup Instructions');
                                $(this).find('a.addToBasket img').attr('src','/images/add_file.png');
                                $(this).find('a.addToBasket').attr('add-href', '/add/'+$(this).find('a.addToBasket').attr('data-carrier_id'));
                            });
                        });
                    }
                },'json'
            );

        });
    };
    var searchDropDownListener = function () {
        $(document).on('click', '.search_category', function(){
            var searchCategory = $(this).val();
            var flags = ['commercial_downloads', 'personal_downloads', 'other_downloads', 'real_time_integration'];

            if(flags.indexOf(searchCategory) != -1){
                $.post('/getItemsLisr',{
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    search:searchCategory
                },function (response) {
                    var html = '<option value="" disabled selected>Select Item</option>';
                    for (var i=0; i<response.itemsList.length; i++){
                        html += '<option value="'+response.itemsList[i].title+'">'+response.itemsList[i].title+'</option>'
                    }
                    $('.search-select').empty().html(html);
                    $('.search-input').hide();
                    $('.search-select').show();
                },'json');
            } else {
                $('.search-input').val('');
                $('.search-input').show();
                $('.search-select').hide();
            }
        });
        $(document).on('click', '.search-select', function(){
            var selectedItem = $(this).val();
            $('.search-input').val(selectedItem);
        });
    };
    var removeDubleScroll = function () {
        $('html').css('overflow-x','visible');
    };

    var statusTooltipInit = function () {
        $(document).find('.status-tooltip').tooltip()
    };

	// init section:
	initToggle();
	initModalSave();
	initLineControl();
	initItemsToggle();
    initLog();
    initDownloadForms();
    removeDubleScroll();
    statusTooltipInit();
    searchDropDownListener();
	return this;

}) (jQuery);