(function ($) {

	var fixHelperModified = function(e, tr) {
	    var $originals = tr.children();
	    var $helper = tr.clone();
	    tr.parent().find('tr.edit-form').each(function() {
	    	$(this).next().find('td').first().append('<div class="editTr" style="display: none;"></div>')
	      	       .find('.editTr').text($(this).get(0).outerHTML);
	    }).remove();
	    $helper.addClass('moved');
	    $helper.children().each(function(index) {
	        $(this).width($originals.eq(index).width() + 30)
	    });
	    return $helper;
	},

	onStop = function(e, ui) {
		var toUpdate = {};
	    ui.item.parent().find('tr').each(function (i) {
	        toUpdate[$(this).attr('id')] = i + 1;
	    });
	    $.ajax({
	    	url: ui.item.parent().attr('update-order-href'),
	    	type: 'POST',
	    	data: {
	    		'orders': toUpdate,
	    		_token: $('meta[name="csrf-token"]').attr('content'),
	    	}
	    });

	    ui.item.parent().find('tr').each(function() {
	    	editFormDiv = $(this).find('div.editTr');
	    	if (editFormDiv.length == 0) {
	    		return;
	    	}
	    	$(this).before(editFormDiv.text());
	    	editFormDiv.remove();
	    })
	};

	$(".table-sortable tbody").sortable({
	    helper: fixHelperModified,
	    stop: onStop,
	    placeholder: "sortable-placeholder",
	    axis: 'y',
	    cursor: 'move',
	   	forceHelperSize: true,
	}).disableSelection();

}) (jQuery);