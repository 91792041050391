(function ($) {
	
	function logMessage(message,type){
		var alertType = 'alert-'+type;
		$('.valid-log-message>div').attr('class','alert '+alertType);
		$('.valid-log-message>div').text = message ;
		$('.valid-log-message').fadeIn();
		setTimeout(function () {$('.valid-log-message').fadeOut();}, 5000);
		return;
	}
	
	var initFormCreate = function() {
		$('.addRecord .add').click(function(e) {
			e.preventDefault();
			var tableLastRow = $(this).closest('.table').find('tbody').children().last();
			var href = $(this).closest('tr').attr('add-href');
			var title = $(this).parent().prev().find('[name=title]').val();
			$.ajax({
		    	url: href,
		    	data: {
		    		title: title,
		    		_token: $('meta[name="csrf-token"]').attr('content'),
		    	},
		    	type: 'post',
		    	dataType: 'json',
		    }).error(function(data) {
		    	var errorsText = [];
		    	for (var i in data.responseJSON) {
		    		errorsText.push(i + ':' + data.responseJSON[i].join(', '));
		    	}
				logMessage(errorsText,'danger');
		    }).done(function(data) {
		    	if (data.status == 'ok') {
					logMessage('Field added.','success');
		    		tableLastRow.after(
						'<tr class="edit-form" edit-href="' + data.editLink + '" style="display: none;">'+
							'<td><input type="text" name="title" class="form-control" value="' + title + '"></td>'+
							'<td><a class="btn btn-success edit"><i class="fa fa-pencil"></i></a></td>'+
						'</tr>'+
						'<tr class="view" order="0" id="'+data.id+'">'+
							'<td class="line-title">' + title + '</td>'+
							'<td>'+
								'<a class="editRecord" edit-href="' + data.editLink + '"><i class="fa fa-pencil" style="margin-right: 4px;"></i></a>'+
								'<a class="deleteRecord" delete-href="' + data.deleteLink + '"><i class="fa fa-trash-o"></i></a>'+
							'</td>'+
						'</tr>'
					);
					$('.addRecord input').val('');
		    	}
		    });
		});
	};

	var initFormEdit = function() {
		$(document).on('click', '.editRecord', function(e) {
			$(this).parent().parent().hide().prev().show();
		});
		$(document).on('click', 'tr.edit-form .edit', function(e) {
			e.preventDefault();
			var tableEditRow = $(this).parent().parent();
			var tableViewRow = $(this).parent().parent().next();
			var href = $(this).parent().parent().attr('edit-href');
			var title = $(this).parent().prev().find('[name=title]').val();
			$.ajax({
		    	url: href,
		    	data: {
		    		title: title,
		    		_token: $('meta[name="csrf-token"]').attr('content'),
		    	},
		    	type: 'post',
		    	dataType: 'json',
		    }).error(function(data) {
		    	var errorsText = [];
		    	for (var i in data.responseJSON) {
		    		errorsText.push(i + ':' + data.responseJSON[i].join(', '));
		    	}
		    	bootbox.alert(errorsText.join('<br>'));
		    }).done(function(data) {
		    	if (data.status == 'ok') {
		    		bootbox.alert('Success');
		    		tableEditRow.hide();
					tableViewRow.show().children().first().html(title);
		    	}
		    });
		});
	};

	var initDelete = function() {
		$(document).on('click', '.deleteRecord', function(e) {
			e.preventDefault();
			var row = $(this).parent().parent();
			var deleteUrl = $(this).attr('delete-href');
			bootbox.dialog({
				message:"Click Delete to permanently remove "+$(row).find('.line-title').text()+" from all carriers in carrier list",
				closeButton:true,
				animate:true,
				buttons:{
					"Cancel":{
						className:"btn btn-default",
						callback:function(result){
							this.modal('hide');
						}
					},
					"Delete":{
						className:"btn btn-danger",
						callback:function(result) {
							if (result) {
								$.ajax({
									url: deleteUrl,
									type: 'post',
									data: {
										_token: $('meta[name="csrf-token"]').attr('content'),
									},
									type: 'post',
									dataType: 'json',
								}).done(function(data) {
									if (data.status == 'ok') {
										bootbox.alert('Item deleted');
										row.remove();
									}
								});
							}
						}
					},
				}
			});
		});
	};

	initFormCreate();
	initFormEdit();
	initDelete();

}) (jQuery);