(function($) {

	var initCarriersList = function() {
		$('.carrier-row a.show-details').click(function() {
			$(this).find('.fa').toggleClass('fa-caret-right').toggleClass('fa-caret-down');
			$(this).parent().parent().parent().toggleClass('active').next().toggle();
			return false;
		})
	};

	var initCarriersDelete = function() {
		$('.deleteCarrier').click(function(e) {
			e.preventDefault();
			var removeUrl = $(this).attr('delete-href');
			var carrierName = $(this).attr('carrier-name');
			var deleteCarrierButton = $(this);

			bootbox.dialog({
				message: 'Click Delete Carrier to permamnently remove ' + carrierName + ', subcarriers and properties' +
				         ' from the Carrier Download List.',
				title: 'Delete carrier',
				buttons: {
					success: {
						label: 'Delete carrier',
						className: 'btn-danger',
						callback: function() {
							$.ajax({
								url: removeUrl,
								data: {
									_token: $('meta[name="csrf-token"]').attr('content'),
								},
								type: 'POST',
								dataType: 'html',
							}).done(function() {
								bootbox.alert(
									'Carrier, subcarriers, and properties have' +
									' been deleted from the Carrier Download List'
								);
								deleteCarrierButton.closest('.carrier-details').prev().remove();
								deleteCarrierButton.closest('.carrier-details').remove();
							})
						}
					},
					close: {
						label: 'Cancel',
						className: 'btn-default',
						callback: function() {}
					},
				}
			});
		})
	};

	var initAddToBasketButton = function() {

		$('.addToBasket').click(function() {
			var el = this;
			var carrierId = $(this).data('carrier_id');
			$.ajax({
				url: $(this).attr('add-href'),
				data: {
					_token: $('meta[name="csrf-token"]').attr('content'),
				},
				type: 'POST',
				dataType: 'json',
			}).done(function(data) {
                if (data.isInBasket) {
					$(el).find('span').text('Remove from My Download Setup Instructions');
					$(el).find('img').attr('src', $(el).find('img').attr('src').replace('add', 'remove'));
					$(el).find('img').attr('title','Remove from My Download Setup Instructions');
					$(el).attr('add-href', '/remove/'+carrierId);
                } else {
					$(el).find('span').text('Add to My Download Setup Instructions');
					$(el).find('img').attr('src', $(el).find('img').attr('src').replace('remove', 'add'));
					$(el).find('img').attr('title','Add to My Download Setup Instructions');
					$(el).attr('add-href', '/add/'+carrierId);
                }
				$.get($('.basket-row').attr('update-href'), {}, function(data) {
					$('.basket-row').html(data);
				});
				$.get($('.delete-basket-row').attr('update-href'), {}, function(data) {
					$('.delete-basket-row').html(data);
				});
				
			})
		});
	};

	var reinitAddToBasketButton = function () {
		$(document).on('click','#modalDeleteItems input.carrier-ids',function(){
			var carrierId = $(this).val();
			if(!$(this).prop('checked')){
				$(document).find('table .full-info a[add-href="/remove/'+carrierId+'"]').find('span').text('Add to My Download Setup Instructions');
				$(document).find('table .full-info a[add-href="/remove/'+carrierId+'"]').find('img').attr('src','/images/add_file.png');
				$(document).find('table .full-info a[add-href="/remove/'+carrierId+'"]').attr('add-href', '/add/'+carrierId);
			} else {
				$(document).find('table .full-info a[add-href="/add/'+carrierId+'"]').find('span').text('Remove from My Download Setup Instructions');
				$(document).find('table .full-info a[add-href="/add/'+carrierId+'"]').find('img').attr('src','/images/basket-remove.png');
				$(document).find('table .full-info a[add-href="/add/'+carrierId+'"]').attr('add-href', '/remove/'+carrierId);
			}
		});
	};

	var initUpdateBasketButton = function() {
		$(document).on('click','#update-basket',function(e) {
			e.preventDefault();
			var itemLenght = $('#modalDeleteItems input.carrier-ids').size();
			var carrierIds = [];
			$(".carrier-ids:checked").each(function() {
				carrierIds.push(this.value);
			});
			var flag = itemLenght != carrierIds.length ? true : false;
			$.post('/removeItems',
				{
					_token: $('meta[name="csrf-token"]').attr('content'),
					carrierIds: carrierIds
				},
				function(data) {
					$(document).find('#modalDeleteItems').modal("hide");
					$(document).find('.modal-backdrop.fade.in').remove();
					$.get($('.basket-row').attr('update-href'), {}, function(data) {
						$('.basket-row').html(data);
						var count = $('#modalDownloadInstructions').data('items_count');
						var basketImage = $('#modalDownloadInstructions').data('basket_image');
						if (count == 0)	{
							$(document).find('#modalDeleteItems').modal("hide");
						} else {
							if(flag){
								bootbox.alert('Carrier has been successfully removed from your basket', function() {
									$('#modalDownloadInstructions').modal("show");
								});
							} else {
								$('#modalDownloadInstructions').modal("show");
							}
						}
					});
					$.get($('.delete-basket-row').attr('update-href'), {}, function(data) {
						$('.delete-basket-row').html(data);
					});
				},'json'
			);
		});
	};

	var initHarmony = function() {
		$('.harmony .harmony-toggle').click(function() {
			if ($(this).parent().hasClass('active')) {
				return;
			}
			$(this).find('.fa').toggleClass('fa-angle-down').toggleClass('fa-angle-right');
			$(this).parent().parent().find('.info.active .content').slideUp().parent().removeClass('active').find('.fa').toggleClass('fa-angle-down').toggleClass('fa-angle-right');
			$(this).parent().addClass('active').find('.content').slideDown();
		});
	};

	var initOpenModalDownloadInstructions = function() {
		$(document).on('click', '#openModalDownloadInstructions', function (){
			var count = $('#modalDownloadInstructions').data('items_count');
			var basketImage = $('#modalDownloadInstructions').data('basket_image');
			if (count == 0)
			{
				bootbox.alert('You have not added carrier download instructions to your list.<br> Go to a carrier and' +
					' click the <img src="' + basketImage + '"> icon to add download instructions.', function() {
				});
			} else {
				$('#modalDownloadInstructions').modal("show");
			}
		});
	};

	var initRemoveItems = function() {
		$(document).on('click', '#remove-items', function (){
			$('#modalDownloadInstructions .modal-header .close').trigger("click");
			$('#modalDeleteItems').modal("show");
			return false;
		});
		$(document).on('click','#modalDeleteItems .modal-footer a[data-dismiss="modal"]', function(){
			setTimeout(function () {
				$.get($('.basket-row').attr('update-href'), {}, function(data) {
					$('.basket-row').html(data);
					$.get($('.delete-basket-row').attr('update-href'), {}, function(data) {
						$('.delete-basket-row').html(data);
						$('#openModalDownloadInstructions').trigger('click');
					});
				});

			},400);
		});
	};


	var goToElement = function() {
		var elem =  $('.carrier-row.active')[0];
		if(elem != undefined){
			$('html, body').animate({
				scrollTop: $(elem).offset().top
			}, 0);
		}
	};

	$('.search-form .search-button-col button').on('click',function () {
		$('search_letter').val('');
		$('search_letter').attr('value','');
		location.href=location.href.replace(/&?search_letter=([^&]$|[^&]*)/i, "");
		location.href=location.href.replace(/&?letter=([^&]$|[^&]*)/i, "");
	});
	$(document).on('click','.clear-search',function () {
		$(document).find('select[name="search_where"]  option').removeAttr('selected');
		$(document).find('select[name="search_where option"] option[value="carriers/subcarriers"]').attr('selected',true);
		$(document).find('.search-text-col input[name="search"]').val('');
	});


	$('body').on('click','.carrier_remove_cart_item',function () {

		var carrier_id = $(this).attr('carrier_id');
		var this_btn = this;
		$.ajax({
			url: '/remove/'+carrier_id,
			data: {
				_token: $('meta[name="csrf-token"]').attr('content'),
			},
			type: 'POST',
			dataType: 'json',
		}).done(function(data) {
			var el = $( "a[data-carrier_id='"+carrier_id+"']" );
			if (data.isInBasket) {
				$(el).find('span').text('Remove from My Download Setup Instructions');
				$(el).find('img').attr('src', $(el).find('img').attr('src').replace('add', 'remove'));
				$(el).find('img').attr('title','Remove from My Download Setup Instructions');
				$(el).attr('add-href', '/remove/'+carrier_id);
			} else {
				$(el).find('span').text('Add to My Download Setup Instructions');
				$(el).find('img').attr('src', $(el).find('img').attr('src').replace('remove', 'add'));
				$(el).find('img').attr('title','Add to My Download Setup Instructions');
				$(el).attr('add-href', '/add/'+carrier_id);
			}


			$(this_btn).parent('.carrier_remove_cart_block').remove();
			$(document).find('.count-user-basket').html(' ('+$('.carrier_remove_cart_block').size()+')');
			if($('.carrier_remove_cart_block').size() <=0){
				$(document).find('#modalDeleteItems').modal("hide");
				$(document).find('.modal-backdrop.fade.in').remove();
				$.get($('.basket-row').attr('update-href'), {}, function(data) {
					$('.basket-row').html(data);
				});
				$.get($('.delete-basket-row').attr('update-href'), {}, function(data) {
					$('.delete-basket-row').html(data);
				});

			}
		});
	});


	$('input[type=file]').bootstrapFileInput();	
	initRemoveItems();
	initAddToBasketButton();
	initCarriersDelete();
	initCarriersList();
	initHarmony();
	initOpenModalDownloadInstructions();
	initUpdateBasketButton();
	reinitAddToBasketButton();
	goToElement();

}) (jQuery);